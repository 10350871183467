import React, { Component } from 'react'
import {database} from 'firebase'

export default class InfoComponent extends Component {
    constructor(props){
        super(props)

        this.state = {
            company_info:"",
            loading:true
        }
    }
    componentWillMount(){
        database().ref().child("info").child("company_information").once("value", infoSnapshot=>{
            if(infoSnapshot.val())
                this.setState({loading: false, company_info: infoSnapshot.val()})
            else
                this.setState({loading: false})
        })
    }
    prettyInfo = (company_info)=>{
        let p = company_info.split(".\n")
        
        for (let index = 0; index < p.length; index++) {
            if(index != p.length-1)   
                p[index] = p[index] + '.'         
        }
        return p
    }
    render() {
        return (
            <div className="container-fluid" >
                <div className="container">
                    <div className="row p-4">
                        <div className="col-sm-12 col-lg-6 p-4">
                            { this.state.loading?<div class="spinner-border text-dark text-center" role="status">
                           <span class="sr-only">Loading...</span>
                         </div>:this.prettyInfo(this.state.company_info).map(p=>{
                                return <p className="">{p }</p>  
                            })}
                        </div>
                                  
                        <div className="col-sm-12 col-lg-6 p-4">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/2wkK-Mm1mbU" allowfullscreen></iframe>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

