import React, { Component } from 'react'
import { MdMenu } from "react-icons/md";
import {Link, Redirect} from "react-router-dom";

import logo from '../sources/images/logo.png'
import menuIcon from '../sources/images/menu.svg'

export default class HeaderComponent extends Component {
    constructor(pros){
        super(pros)
        this.state = {
          movile:false,
          collapse: false
        }

    }
    
    componentWillMount = ()=>{
      // Check device width
      if(window.innerWidth<1000)
        this.setState({movile: true, collapse: false})        
      else
        this.setState({movile: false, collapse: true})        
      

      window.addEventListener("resize", (e)=>{
      if(e.target.innerWidth<1000)
        this.setState({movile: true, collapse: false })
      else
        this.setState({movile: false, collapse: true})        
      })
    }
    render() {
        
        return (
        <nav className="bg-white p-2 fixed-top">
         <div className="container">
           <div className="row">
              <div className="col-6">
                <Link to="/"><img src={logo} alt="logo" style={{width:"165px"}} /></Link>
              </div>
              <div className="col-6">
                  {this.state.movile && <img onClick={()=>this.setState(prev=>({collapse:!prev.collapse}))} src={menuIcon} style={{width:40, zIndex:10, position:'absolute', top:10, right:0}}/>}
                  {this.state.collapse && <div onClick={()=>{
                    if(this.state.movile) this.setState({collapse: false})
                  }} className={`row mt-4 ${this.state.movile?"":"text-right"}`} >
                  <Link className="col-lg-3 col-sm-12 nav-link menu-text" to="/">INICIO</Link>
                  <Link to="/productos" className="col-lg-3 col-sm-12 nav-link menu-text">PRODUCTOS</Link>
                  <Link to="/contactos" className=" col-lg-3 col-sm-12 nav-link menu-text ">CONTACTO</Link>
                </div>}
              </div>
           </div>
         </div>
       </nav>)
    }
}
 