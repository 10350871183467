import React, { Component } from 'react'
import {useParams} from 'react-router-dom'
import {database} from 'firebase'

import ProductDetailComponent from '../components/ProductDetailComponent'

export default class ProductPage extends Component {
    constructor(props){
        super(props)

        this.state = {
            categories:null,
            search:"",
            selectedCat: 0,
            products:null,
            selectedProduct: null,
            notResult: false,
            loading: true
        }

        this.searchTime = null;
    }


    loadProducts = ()=>{
        
            if(this.state.selectedCat != 0){
                database().ref("products").orderByChild('category_id').equalTo(this.state.selectedCat).once("value", result=>{
                
                    if(result.val()){
                        let rawPro = result.val()
                        let ProKeys = Object.keys(rawPro)
                        let ProArr = []
                        ProKeys.map(key=>{
                            ProArr.push(rawPro[key])
                    })
                    this.setState({products: ProArr, notResult:false, loading: false})
                    }else{
                        this.setState({notResult: true, loading: false})
                    }
                })
            }else{
                database().ref("products").once("value", result=>{
                
                    if(result.val()){
                        let rawPro = result.val()
                        let ProKeys = Object.keys(rawPro)
                        let ProArr = []
                        ProKeys.map(key=>{
                            ProArr.push(rawPro[key])
                    })
                    this.setState({products: ProArr, notResult:false, loading: false})
                    }else{
                        this.setState({notResult: true, loading: false})
                    }
                })
            }
    }



    componentWillMount(){
       
       
       database().ref().child('categories').once('value', catsnapshot=>{
         let catArrObj = catsnapshot.val()
         let categories = []
         Object.keys(catArrObj).map(catKey=>{
            categories.push(catArrObj[catKey])
         })   
         this.setState({categories})      
       })
       
    }

    searchHandler = ()=>{
        if(this.state.search.toString() === ""){
            return this.loadProducts()
    }


        database().ref("products").orderByChild("nameToLower").startAt(this.state.search.toString().toLowerCase()).once("value", result=>{
            
            if(result.val()){
                let rawPro = result.val()
                let ProKeys = Object.keys(rawPro)
                let ProArr = []
                ProKeys.map(key=>{
                    ProArr.push(rawPro[key])
            })
            this.setState({products: ProArr, notResult:false, loading: false})
            }else{
                this.setState({notResult: true, loading: false})
            }
        })
    }

    selectedCat = (catId)=>{
        
        if(catId != 0)
            database().ref("products").orderByChild("category_id").equalTo(catId).once("value", result=>{
                
                if(result.val()){
                    let rawPro = result.val()
                    let ProKeys = Object.keys(rawPro)
                    let ProArr = []
                    ProKeys.map(key=>{
                        ProArr.push(rawPro[key])
                })
                this.setState({products: ProArr, selectCategory: catId, loading:false, notResult:false})
                }else{
                    this.setState({notResult: true, loading:false, selectCategory: catId})
                }
            })
        else    
            database().ref("products").once("value", result=>{
                    
                if(result.val()){
                    let rawPro = result.val()
                    let ProKeys = Object.keys(rawPro)
                    let ProArr = []
                    ProKeys.map(key=>{
                        ProArr.push(rawPro[key])
                })
                this.setState({products: ProArr, selectCategory: catId, loading:false, notResult:false})
                }else{
                    this.setState({notResult: true, loading:false, selectCategory: catId})
                }
            })
    }

 
    componentDidMount(){
        let catId = 0;
        try{
           catId = this.props.match.params.category_id
        }catch(err){
            console.log("category param err ", err)
        }
        this.setState({selectedCat: catId})
        this.setState(prev=>{
            this.selectedCat(catId)
            return{selectedCat: catId}
        })      
      
    }
    closeProduct = ()=>{
        this.setState({selectedProduct: null})
    }
    render() {
        return (<div style={{marginTop: 90, minHeight: window.innerHeight-180}} className="container-fluid product-container p-4">
        <div className="container p-4">
            
            
          {
              this.state.selectedProduct? <ProductDetailComponent proData={this.state.selectedProduct} closeProduct={this.closeProduct}/> : <div className="container"> 
                  <div className="row">
              <h3 className="col-sm-12 col-lg-6 text-white p-4">Productos</h3>

              <div className="col-sm-12 col-lg-6 ">
                <form className="form float-right mt-3 text-center w-100">
                    <div className="form-group row">
                        <select value={this.state.selectedCat} onChange={cat=>{
                            this.setState({selectedCat: cat.target.value, loading: false})
                            this.selectedCat(cat.target.value)
                        }} className="form-control col-sm-12 col-lg-5 mt-1">
                            <option value={0}>Todas las categorias</option>
                            {
                                this.state.categories && this.state.categories.map(cat=>(
                                    <option value={cat.id}>{cat.name}</option>
                                ))
                            }
                        </select>
                        <input value={this.state.search} onChange={text=>{
                            this.setState({search: text.target.value})
                            this.searchTime = null;
                            this.searchTime = setTimeout(()=>this.searchHandler(), 700)
                        }} className={`form-control col-sm-12 col-lg-5 mt-1 ${window.innerWidth<768?null:"ml-4"}`} type="search" placeholder="Buscar"/>
                    </div>                     
                </form>               
            </div>
           
          </div>
          <div className="container">
                      <div className="row">
                        {
                           this.state.loading?<div class="spinner-border text-light text-center" role="status">
                           <span class="sr-only">Loading...</span>
                         </div>:this.state.notResult?<div className="p-4">
                               <h3>No hay resultados</h3>
                            </div>: this.state.products && this.state.products.map(pro=>{
                            return <div onClick={()=>{
                                this.setState({selectedProduct: pro})
                            }} className="col-sm-12 col-lg-4 text-center p-4 product-c">
                                        <div className="h-100 w-100 product">
                                            <img className="w-100 h-100 " src={pro.image_url[0]}/>
                                        </div>
                                        <div className="text-white align-top text-center p-1" style={{marginTop:-49, background:"#000", opacity:0.7}}>
                                            <h3 className="text-center align-top">{pro.name}</h3>
                                        </div>
                                    </div>
                                })
                        }
                        </div>
                  </div>
              </div>
          }
                  
        </div>
        
      </div>)
    }
}
