import React, { Component } from 'react'
import {MdClose} from 'react-icons/md'
export default class ProductDetailComponent extends Component {
    render() {
        const {name, description, additional_info, image_url} = this.props.proData
        return (
            <div className="p-4 rounded " style={{width:"70vw", background:'#fff', margin:'0px auto'}}>
               <span onClick={this.props.closeProduct} style={{marginTop:-20, marginRight:-15, cursor:'pointer'}}  className="text-danger float-right">
                    <MdClose size={20}/>
                </span>
                
                
                    <div className="row">
                        <div className="col-sm-12 col-lg-5 p-1 m-1 text-center">
                        <div className="container p-0">
                            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner">
                                       {image_url && image_url.map(imageUrl=>(<div className={`carousel-item ${image_url[0]==imageUrl?"active":null}`}>                                
                                           <img className="d-block" src={imageUrl} style={{maxHeight:350}} alt="First slide"/>
                                       </div>))}
                                                              
                                    </div>
                                    
                                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">atras</span>
                                    </a>
                                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">siguiente</span>
                                    </a>
                            </div>
                        
                        </div>
                        </div> 
                        <div className="col-sm-12 col-lg-4 p-1 text-dark">
                            <h4>{name}</h4>
                            {
                                description && <div>
                                    <h5>Descripción</h5>
                                    <p>{description}</p>
                                </div>
                            }
                            <br/>
                            {additional_info && <div>
                                <h5>Información adicional</h5>
                                <p>{additional_info}</p>
                            </div>}
                        </div> 
                    </div>
               
                
                
               
            </div>
        )
    }
}
