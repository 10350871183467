import React, { Component } from 'react'
//Slide images
// 1280x500 px image
import slide1 from '../sources/images/slide1.jpg'
import slide2 from '../sources/images/slide2.jpg'
import slide3 from '../sources/images/slide3.jpg'


export default class SlideShowComponent extends Component {
    render() {
        return (
            <div className="container-fluid p-0" style={{marginTop:90}}>
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">                                
                                <img className="d-block w-100" src={slide1} alt="First slide"/>
                            </div>
                            <div className="carousel-item">                                
                                <img className="d-block w-100" src={slide2} alt="slide"/>
                            </div>   
                            <div className="carousel-item">                                
                                <img className="d-block w-100" src={slide3} alt="slide"/>
                            </div>                        
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">atras</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">siguiente</span>
                        </a>
                </div>
               
            </div>
        )
    }
}
