import React, { Component } from 'react'

import SlideShowComponent from '../components/SlideShowComponent';
import InfoComponent from '../components/InfoComponent';
import CategoryComponent from '../components/CategoryComponent';

export default class HomePage extends Component {
    render() {
        return (
            <div className="container-fluid p-0">
                <SlideShowComponent/>
                <InfoComponent/>
                <CategoryComponent/>
            </div>            
        )
    }
}
