import React, { Component } from 'react'
import {Link, } from 'react-router-dom'
import logo from '../sources/images/logo.png'
import {MdLocationOn, MdPhone, MdEmail} from 'react-icons/md'
import {IoLogoWhatsapp} from 'react-icons/io'
import {database} from 'firebase'

export default class FooterComponent extends Component {
    constructor(props){
        super(props)

        this.state = {
            location:"",
            email:"",
            number:"",
            whatsapp:""
        }
    }

    componentWillMount(){

        database().ref("info").once("value", info=>{
            this.setState({...info.val()})
        })


    }
    render() {
        return (
            <div className="container-fluid p-2 footer m-0 border-top border-dark">
                <footer className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-3 p-2">
                         <Link to="/"><img src={logo} alt="logo" style={{width:100}} /></Link>
                    </div>
                    <div className="col-sm-12 col-md-9">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6 col-lg-3 p-2">
                                    <div style={{color: '#fff'}}>
                                        <MdLocationOn size={25} style={{marginRight:5}}/><span>{this.state.location}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3 p-2">
                                    <div style={{color: '#fff'}}>
                                        <MdPhone size={25} style={{marginRight:5}}/><span>{this.state.number}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3 p-2">
                                    <div style={{color: '#fff'}}>
                                        <IoLogoWhatsapp size={25} style={{marginRight:5}}/><span>{this.state.whatsapp}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3 p-2">
                                    <div style={{color: '#fff'}}>
                                        <MdEmail size={25} style={{marginRight:5}}/><span>{this.state.email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </footer>
            </div>
        )
    }
}
