import React from 'react'
import {database} from 'firebase'
import {Link} from 'react-router-dom'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

export default class CategoryComponent extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            categories: null,
            loading:true
        }

    }
    componentWillMount(){
        database().ref("categories").once("value", result=>{

            //
            if(result.val()){
                let rawCat = result.val()
                let catKeys = Object.keys(rawCat)
                let catArr = []
                catKeys.map(key=>{
                    catArr.push(rawCat[key])
            })
            this.setState({categories: catArr, notResult:false, loading: false})
            }else{
                this.setState({categories: null, loading: false})
            }
            //
        
        })
    }
    render() {
        return (
            <div className="container-fluid brand-color">
                <div className="container p-2">
                <h3 className="text-white">Categorías</h3>    
                {this.state.loading?<div class="spinner-border text-light text-center" role="status">
                           <span class="sr-only">Loading...</span>
                         </div>: this.state.categories?<Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={5000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    >
                    {this.state.categories && this.state.categories.map(cat=>{
                        return <Link to={`/productos/${cat.id}`}>
                            <div className="h-100 m-4">
                                    <div className="h-100 w-100 product">
                                        <img className="w-100 h-100 " src={cat.image_url}/>
                                    </div>
                                    <div className="text-white align-top text-center p-1" style={{marginTop:-90, background:"#000", opacity:0.7}}>
                                        <h3 className="text-center align-top">{cat.name}</h3>
                                    </div>
                            </div>
                        </Link>
                    })}
                    </Carousel>:<div className="p-4 text-white">
                        <h4 >No hay Categoría</h4>
                    </div>}    
                </div>
            </div>
        )
    }
}
