import React,{Component} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";



//pages
import HomePage from './pages/HomePage'
import ProductPage from './pages/ProductPage'
import ContactPage from './pages/ContactPage'
import PrivacyPolicy from './pages/PrivacyPolicy'
// Components
import HeaderComponent from './components/HeaderComponent'
import FooterComponent from './components/FooterComponent'



export default class AppRouter extends Component {
  render() {
    return (
      <Router>
      <div>
      
         <HeaderComponent/>
         <Switch>
           <Route exact path="/" children={<HomePage />}/> 

           <Route path="/productos/:category_id" component={ProductPage}/>
           <Route path="/productos"  children={<ProductPage />}/>  

           <Route path="/contactos" children={<ContactPage/>}/>    
           <Route path="/politica" children={<PrivacyPolicy/>}/>    
         </Switch>
         <FooterComponent/>
       </div>
     </Router>
    )
  }
}
