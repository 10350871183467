import React from 'react';
import Router from './Router.js'
import logo from './logo.svg';
import './App.css';

import * as firebase from 'firebase'

var firebaseConfig = {
  apiKey: "AIzaSyATpZhdXldVo54nkX_RRQ4ZCLxqdP9Sxao",
  authDomain: "thmtodohierro.firebaseapp.com",
  databaseURL: "https://thmtodohierro.firebaseio.com",
  projectId: "thmtodohierro",
  storageBucket: "",
  messagingSenderId: "909175403986",
  appId: "1:909175403986:web:4e55fbe7b6c51585cb840b"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

function App() {
  return (<Router/>);
}

export default App;
