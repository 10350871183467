import React, { Component } from 'react'
import {database} from 'firebase'
import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    Marker,
    InfoWindow
  } from "react-google-maps"

class MapComponent extends Component {
    constructor(props){
        super(props)
        this.state = {
            showInfo: true, 
            setShowInfo:(b)=>{ this.setState({showInfo:b})},
            latlng:[0, 0],
            info:null
        }
    }

    componentWillMount(){
        database().ref().child("info").once('value', latlngSnapshot=>{
            let data = latlngSnapshot.val().latlng
            this.setState({info: latlngSnapshot.val(), latlng:[data["0"], data["1"]]})
        })
    }
    render() {
        return (
        <GoogleMap
            defaultZoom={15}
            defaultCenter={{ 
                lat: this.state.latlng[0], 
                lng: this.state.latlng[1]}}>        
            <Marker           
                position={{ 
                    lat: this.state.latlng[0], 
                    lng: this.state.latlng[1]
                }}
                onClick={()=>this.state.setShowInfo(true)}                        
            />
            
            {this.state.showInfo && <InfoWindow
                onCloseClick={() => this.state.setShowInfo(false)
                }
                position={{
                    lat: this.state.latlng[0], 
                    lng: this.state.latlng[1]
                }}>
                <div>
                    <h2>THM todo hierro</h2>
                    <p>{this.state.info && this.state.info.location}</p>
                    <a href={`https://maps.google.com/?ll=${this.state.latlng[0]},${this.state.latlng[1]}`} target="_blank">Ver en el google map</a>
                </div>
            </InfoWindow>}
        
      </GoogleMap>
        )
    }
}

const MapWrapped = withScriptjs(withGoogleMap(MapComponent))

export default MapWrapped