import React, { Component, useState, useEffect } from 'react'
import {MdLocationOn, MdPhone, MdEmail} from 'react-icons/md'
import {IoLogoWhatsapp} from 'react-icons/io'
import {database} from 'firebase'
import MapComponent from '../components/MapComponent'

export default class ContactPage extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading:true,
            info:null,
            clientName:"",
            clientMessage:""
        }
        // References
        this.contactForm = React.createRef()
    }

    componentWillMount(){
        database().ref("info").once("value", infoSnapshot=>{{
            this.setState({info: infoSnapshot.val(), loading: false})
        }})
    }

    conactHandler = e=>{
      e.preventDefault()
      // Send message to admin
      let url = `https://api.whatsapp.com/send?phone=+${this.state.info.whatsapp}&text=(${this.state.clientName.toUpperCase()}) ${this.state.clientMessage}`
      window.open(encodeURI(url), "_blank")
      // clear form
      this.setState({clientName:"", clientMessage:""})
      
    }
    render() {
        return (
            <div className="container"  style={{marginTop: 90, minHeight: window.innerHeight-180}}>
                <h3 className="p-4">Contacto</h3>
                <div className="row p-4">
                <div style={{ width: "100vw", height: "60vh" }}>
                    <MapComponent
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyD4vDoFwqVDo-by1Pnntz6uGLZtdPkXgYs`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <div className="container rounded mb-2">
                          <form ref={this.contactForm} className="form" onSubmit={this.conactHandler}>
                              <div className="form-group">
                                  <input required value={this.state.clientName} onChange={text=>this.setState({clientName: text.target.value})} type="text" className="form-control simplebox" placeholder="Nombre"/>
                              </div>
                              
                              <div className="form-group">
                                  <textarea required value={this.state.clientMessage} onChange={text=>this.setState({clientMessage: text.target.value})} type="text" rows="5" className="form-control simplebox" placeholder="Mensaje"/>
                              </div>
                              <button type="submit" className="btn btn-block text-white bg-dark simplebox "><IoLogoWhatsapp className="align-middle pb-1" size={20}/> Enviar</button>
                          </form>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        { this.state.loading?<div class="spinner-border text-dark text-center" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>:this.state.info && <div className="container-fluid ">
                            
                                <div className="row p-2 ml-2">
                                    <MdLocationOn size={25}/>
                                    <span className="mb-3 ml-2 text-uppercase">{this.state.info.location}</span>
                                </div>
                                <div className="row p-2 ml-2">
                                    <MdPhone size={25}/>
                                    <span className="mb-3 ml-2 text-uppercase"><a className="nav-link" href={`tel:${this.state.info.number}`}>{this.state.info.number}</a></span>
                                </div>
                                <div className="row p-2 ml-2">
                                    <IoLogoWhatsapp size={25}/>
                                    <span className="mb-3 ml-2 text-uppercase"><a className="nav-link" href={`https://api.whatsapp.com/send?phone=${this.state.info.whatsapp}`}>{this.state.info.whatsapp}</a></span>
                                </div>
                                <div className="row p-2 ml-2">
                                    <MdEmail size={25}/>
                                    <span className="mb-3 ml-2 text-uppercase">{this.state.info.email}</span>
                                </div>
                            </div>}
                    </div>
                </div>
        </div>
        )
    }
}
